<template>
  <div class="partners">
    <h1 class="partners__title">{{ $t('partners') }}</h1>
    <div class="partners__content">
      <div class="partners__content-wrapper">
        <div class="partners__content-wrapper-images">
          <img src="@/assets/images/a-pro-logo.png" />
        </div>
      </div>
<!--      <ui-button v-if="getUser.role === 'Admin'" color="error">-->
<!--        <img src="@/assets/svg/icon-plus-white.svg" alt="" />-->
<!--        Добавить партнера-->
<!--      </ui-button>-->
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "Partners",

  computed: {
    ...mapGetters(['getUser'])
  }
}
</script>

<style lang="scss" scoped>
.partners {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  height: 100%;
  padding: 150px 163px 56px 50px;
  background: #F5F5F5;
  gap: 30px;

  @media(max-width: 768px) {
    padding: 104px 20px 56px 20px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
  }
  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #343432;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    max-width: 774px;

    button {
      max-width: 366px;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 40px;
      background: #FFFFFF;
      border-radius: 20px;
      max-width: 774px;
      max-height: 334px;
      height: 100%;
      width: 100%;

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        color: #1B1A1F;
        border-bottom: 1px solid #F5F5F5;
        padding-bottom: 30px;
      }

      &-images {
        display: flex;
        align-items: center;
        gap: 20px;

        img {
          width: 250px;
          height: 99px;
        }
      }
    }
  }
}
</style>
